import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import PosState from "@/types/state/pos";
import PartsService from "@/services/PartsService";
import RegService from "@/services/RegService";
import PaymentService from "@/services/PaymentService";
import StaxService from "@/services/StaxService";
import Utils from "@/utility/utils";
import store from "..";
import Order from "@/types/salesorder";
import SalesService from "@/services/SalesService";
import LocalDesktopService from "@/services/LocalDesktopService";
import { cloneDeep } from "lodash";

type storedCartItemsTypes = {
  items: Array<any>;
  type: "summaryOrder" | "orderInvoice";
};

const namespaced = true;

const partsService = new PartsService();
const salesService = new SalesService();
const regService = new RegService(process.env.VUE_APP_ABSTRACTION_API);
const paymentService = new PaymentService(process.env.VUE_APP_ABSTRACTION_API);
const staxService = new StaxService();
const localDesktopService = new LocalDesktopService();

export const state: PosState = {
  salesOrder: {
    order: new Order(),
    oldOrder: new Order(),
    customer_name: "",
    date_created: new Date(),
    id: "",
    racks: [],
  },
  currentOrder: [],
  unsavedOrders: [],
  tenders: [],
  register: null,
  registerOptions: [],
  registerPrinter: null,
  defaultPickTicketPrinter: null,
  customer: null,
  transactionId: null,
  user: null,
  taxAmount: null,
  lastOrderId: null,
  lastOrder: null,
  lastOrderCustomerContacts: [],
  priceStrategy: null,
  autoAddParts: true,
  autoLoadDefaultCustomers: false,
  loadOpenOrdersInParts: false,
  lastItemChanged: null,
  changeAmount: null,
  storedCartItems: {
    summaryOrder: [],
    orderInvoice: [],
  },
  sessionObject: null,
  shippingAddress: null,
  cacheStats: {},
  currentActivity: "",
  allowValidation: false,
  hidePosCustomLineItems: false,
  selectedFilterOptions: [],
  selectedCategory: "",
  entryUser: null,
  validationLoading: false,
  keyboardShortcutsMode: false,
  autosaveDraftOrder: false,
};

export const getters: GetterTree<PosState, RootState> = {
  getCurrentOrder: (state) => {
    return state.currentOrder;
  },
  getCurrentUnsavedOrder: (state) => {
    return state.salesOrder;
  },
  getSalesOrder: (state) => {
    return state.salesOrder.order;
  },
  getOldSalesOrder: (state) => {
    return state.salesOrder.oldOrder;
  },
  getRacks: (state) => {
    return state.salesOrder.racks;
  },
  getTenders: (state) => {
    return state.tenders;
  },
  getChangeAmount: (state) => {
    return state.changeAmount;
  },
  getRegister: (state) => {
    return state.register;
  },
  isValidPOS: (state) => {
    return state.register || state.register?.status != "Closed" || state.user;
  },
  getCustomer: (state) => {
    return state.customer;
  },
  getRegisterPrinter: (state) => {
    return state.registerPrinter;
  },
  getDefaultPickTicketPrinter: (state) => {
    return state.defaultPickTicketPrinter;
  },
  getTranId: (state) => {
    return state.transactionId;
  },
  getTaxAmount: (state) => {
    return state.taxAmount;
  },
  getLastOrderId: (state) => {
    return state.lastOrderId;
  },
  getLastOrderCustomerContacts: (state) => {
    return state.lastOrderCustomerContacts;
  },
  getPriceStrategy: (state) => {
    return state.priceStrategy;
  },
  getAutoAddParts: (state) => {
    return state.autoAddParts;
  },
  getAutoLoadDefaultCustomers: (state) => {
    return state.autoLoadDefaultCustomers;
  },
  getLoadOpenOrdersInParts: (state) => {
    return state.loadOpenOrdersInParts;
  },
  getLastItemChanged: (state) => {
    return state.lastItemChanged;
  },
  getStoredCartItems: (state) => {
    return state.storedCartItems;
  },
  getSessionObject: (state) => {
    return state.sessionObject;
  },
  getShippingAddress: (state) => {
    return state.shippingAddress;
  },
  getCacheStats: (state) => {
    return state.cacheStats;
  },
  getCurrentActivity: (state) => {
    return state.currentActivity;
  },
  getSelectedFiltersOptions: (state) => {
    return state.selectedFilterOptions;
  },
  getHidePosCustomLineItems: (state) => {
    return state.hidePosCustomLineItems;
  },
  getSelectedCategory: (state) => {
    return state.selectedCategory;
  },
  getEntryUser: (state) => {
    return state.entryUser;
  },
  getValidationLoading: (state) => {
    return state.validationLoading;
  },
  getRegisterOptions: (state) => {
    return state.registerOptions;
  },
  getLastOrder: (state) => {
    return state.lastOrder;
  },
  isKeyboardShortcutsMode: (state) => {
    return state.keyboardShortcutsMode;
  },
  getAutosaveDraftOrder: (state) => {
    return state.autosaveDraftOrder;
  },
};

export const mutations: MutationTree<PosState> = {
  CLEAR_ORDER(state) {
    state.currentOrder = [];
    state.salesOrder = {
      order: new Order(),
      oldOrder: new Order(),
      customer_name: "",
      date_created: new Date(),
      id: "",
      racks: [],
    };
    state.entryUser = null;
    state.taxAmount = null;
  },
  ADD_TO_ORDER(state, part) {
    if (part.so_id) {
      const salesOrder = new Order();
      salesOrder.initFromSalesOrder(part);
      part = salesOrder;
    }
    part.amount = Number(part.amount);
    const orderIndex = part.so_id
      ? state.currentOrder.findIndex((p: any) => p.so_id === part.so_id)
      : -1;
    const invoiceIndex = part.ar_id
      ? state.currentOrder.findIndex((p: any) => p.ar_id === part.ar_id)
      : -1;

    // Can't have duplicate invoice or orders. Parts are ok.

    if (orderIndex === -1 && invoiceIndex === -1) {
      state.currentOrder
        ? state.currentOrder.unshift(part)
        : (state.currentOrder = [part]);
    }

    state.lastItemChanged = part.part_number || part.so_id || part.ar_id;
  },
  UPDATE_PART_QTY(state, { partIndex, part }) {
    state.currentOrder.splice(partIndex, 1);
    state.currentOrder.unshift(part);
    state.lastItemChanged = part.part_number;
  },
  UPDATE_SHIP_LINE(state, { liIndex, li }) {
    if ("lis_items" in state.currentOrder[0]) {
      state.currentOrder[0].lis_items[liIndex] = li;
    }
  },
  UPDATE_AR_AMT(state, { arIndex, ar }) {
    state.currentOrder[arIndex] = ar;
  },
  REPLACE_ORDER(state, newOrder) {
    for (let i = 0; i < newOrder.length; i++) {
      const checkOrder = newOrder[i];
      if (checkOrder.so_id && !checkOrder.isObject) {
        const order = new Order();
        order.initFromSalesOrder(checkOrder);
        newOrder[i] = order;
      }
    }
    state.currentOrder = newOrder;
  },
  REMOVE_FROM_ORDER(state, partIndex) {
    //Need to remove multiple rack parts at the same time.
    const part: any = state.currentOrder[partIndex];
    if (part.rack) {
      const indicies = (state.currentOrder as any)
        .flatMap((p: any, i: number) => (p.part_id === part.part_id ? i : []))
        .reverse();
      indicies.forEach((index: number) => {
        state.currentOrder.splice(index, 1);
      });
      state.salesOrder.racks = state.salesOrder.racks.filter(
        (r: any) => r.part_id !== part.part_id,
      );
    } else {
      state.currentOrder.splice(partIndex, 1);
    }
  },
  ADD_TO_TENDERS(state, tender) {
    state.tenders ? state.tenders.push(tender) : (state.tenders = [tender]);
  },
  UPDATE_TENDER_AMT(state, { tenderIndex, tender }) {
    state.tenders[tenderIndex] = tender;
  },
  REMOVE_TENDER(state, tenderIndex) {
    state.tenders.splice(tenderIndex, 1);
  },
  CLEAR_TENDERS(state) {
    state.tenders = [];
  },
  ADD_UPDATE_OA_LINE(state, overage) {
    // Add or Update OA line, there can be only one.
    const index = state.currentOrder.findIndex((ar: any) => ar.ar_id === "OA");
    if (index > -1) {
      state.currentOrder[index] = {
        ar_id: "OA",
        type: "OA",
        balance:
          parseFloat(state.currentOrder[index].balance) - parseFloat(overage),
        new_ar_id: "Y",
      } as any;
      state.lastItemChanged = state.currentOrder[index].ar_id;
    } else {
      const ar = {
        ar_id: "OA",
        type: "OA",
        balance: Math.abs(parseFloat(overage)).toString(),
        new_ar_id: "Y",
      } as any;
      state.currentOrder.push(ar);
      state.lastItemChanged = ar.ar_id;
    }
    // if the OA line has a balance of 0, remove it.
    const oaIndex = state.currentOrder.findIndex(
      (ar: any) => ar.ar_id === "OA",
    );
    if (oaIndex > -1 && parseFloat(state.currentOrder[oaIndex].balance) === 0) {
      state.currentOrder.splice(oaIndex, 1);
    }
  },
  ADD_CHANGE(state, overage) {
    const index = state.tenders.findIndex(
      (tender) => tender.payment_type === "CA",
    );
    if (index > -1) {
      state.tenders[index].change_amt = Math.abs(parseFloat(overage));
    }
  },
  SET_REGISTER(state, reg) {
    state.register = reg;
  },
  SET_CUSTOMER(state, cust) {
    state.customer = cust;
  },
  SET_TRAN_ID(state, id) {
    state.transactionId = id;
  },
  SET_CHANGE_AMOUNT(state, amount) {
    state.changeAmount = amount;
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_TAX_AMOUNT(state, amount) {
    state.taxAmount = amount;
  },
  CLEAR_TAX_AMOUNT(state) {
    state.taxAmount = null;
  },
  SET_LAST_ORDER_ID(state, id) {
    state.lastOrderId = id;
  },
  SET_LAST_ORDER_CUSTOMER_CONTACTS(state, contacts) {
    state.lastOrderCustomerContacts = contacts;
  },
  SET_CUSTOMER_CONTACT_ITEMS(state, items) {
    if (state.customer) {
      state.customer["contact_id_items"] = items;
    }
  },
  ADD_RACK(state, rack) {
    const index = state.salesOrder.racks.findIndex((r) => {
      return r.part_id === rack.part_id;
    });
    if (index >= 0) {
      state.salesOrder.racks[index].rack_count = rack.rack_count;
    } else {
      state.salesOrder.racks.push(rack);
    }
  },
  CLEAR_RACKS(state) {
    state.salesOrder.racks = [];
  },
  REMOVE_RACK_BY_PART_ID(state, part_id) {
    state.salesOrder.racks = state.salesOrder.racks.filter(
      (r) => r.part_id != part_id,
    );
  },
  SET_PRICE_STRATEGY(state, priceStrategy) {
    state.priceStrategy = priceStrategy;
  },
  TOGGLE_AUTO_ADD_PARTS(state) {
    state.autoAddParts = !state.autoAddParts;
  },
  TOGGLE_AUTO_LOAD_DEFAULT_CUSTOMERS(state) {
    state.autoLoadDefaultCustomers = !state.autoLoadDefaultCustomers;
  },
  TOGGLE_LOAD_OPEN_ORDERS_IN_PARTS(state) {
    state.loadOpenOrdersInParts = !state.loadOpenOrdersInParts;
  },
  SET_AUTO_ADD_PARTS(state, autoAddParts) {
    state.autoAddParts = autoAddParts;
  },
  SET_AUTO_LOAD_DEFAULT_CUSTOMERS(state, autoLoadDefaultCustomers) {
    state.autoLoadDefaultCustomers = autoLoadDefaultCustomers;
  },
  SET_LAST_ITEM_CHANGED(state, lastItemChanged) {
    state.lastItemChanged = lastItemChanged;
  },
  SET_STORED_CART_ITEMS(state, { items, type }: storedCartItemsTypes) {
    state.storedCartItems[type] = items;
  },
  CLEAR_STORED_CART_ITEMS(state) {
    state.storedCartItems.summaryOrder = [];
    state.storedCartItems.orderInvoice = [];
  },
  SET_SESSION_OBJECT(state, session) {
    state.sessionObject = session;
  },
  SET_SESSION_ID(state, id) {
    state.sessionObject = { SessionID: id };
  },
  REMOVE_UNSAVED_ORDER(state, index) {
    state.unsavedOrders.splice(index, 1);
  },
  SET_SALES_ORDER(state, order) {
    if (order.order.isObject) {
      state.salesOrder.order = order.order;
      state.salesOrder.oldOrder = order.oldOrder;
    } else {
      const newOrder = new Order();
      newOrder.initFromSalesOrder(order.order);
      const oldOrder = new Order();
      oldOrder.initFromSalesOrder(order.oldOrder);

      state.salesOrder.order = newOrder;
      state.salesOrder.oldOrder = oldOrder;
    }

    state.salesOrder.id = order.id;
    state.salesOrder.date_created = order.date_created;
    state.salesOrder.customer_name = order.customer_name;
    state.salesOrder.racks = order.racks || [];
    state.taxAmount = order.order.tax_amount;
  },
  SET_VALIDATE_SALES_ORDER(state, order) {
    const newOrder = new Order();
    newOrder.initFromSalesOrder(order.record);
    const oldOrder = new Order();
    oldOrder.initFromSalesOrder(order.oldRecord);

    state.salesOrder.order = newOrder;
    state.salesOrder.oldOrder = oldOrder;
    state.taxAmount = order.record.tax_amount;
  },
  SET_REGISTER_PRINTER(state, printer) {
    state.registerPrinter = printer;
  },
  SET_DEFAULT_PICKTICKET_PRINTER(state, printer) {
    state.defaultPickTicketPrinter = printer;
  },
  CLEAR_SALES_ORDER(state) {
    state.salesOrder = {
      order: new Order(),
      oldOrder: new Order(),
      customer_name: "",
      date_created: new Date(),
      id: "",
      racks: [],
    };
  },
  SET_CACHE_STATS(state, { key, value }) {
    state.cacheStats[key] = value;
  },
  SET_CURRENT_ACTIVITY(state, activity) {
    state.currentActivity = activity;
  },
  SET_ALLOW_VALIDATION(state, allowValidation) {
    state.allowValidation = allowValidation;
  },
  SET_SELECTED_FILTERS_OPTIONS(state, options) {
    state.selectedFilterOptions = options;
  },
  RESET_SELECTED_FILTERS_OPTIONS(state) {
    state.selectedFilterOptions = state.selectedFilterOptions.map(
      (elem: any) => ({ label: elem.label, value: null }),
    );
  },
  SET_HIDE_POS_CUSTOM_LINE_ITEMS(state, hidePosCustomLineItems) {
    state.hidePosCustomLineItems = hidePosCustomLineItems;
  },
  SET_SELECTED_CATEGORY(state, category) {
    state.selectedCategory = category;
  },
  SET_ENTRY_USER(state, user) {
    state.entryUser = user;
  },
  SET_VALIDATION_LOADING(state, loading) {
    state.validationLoading = loading;
  },
  SET_REGISTER_OPTIONS(state, options) {
    state.registerOptions = options;
  },
  SET_LAST_ORDER(state, order) {
    state.lastOrder = order;
  },
  SET_KEYBOARD_SHORTCUTS_MODE(state, value) {
    state.keyboardShortcutsMode = value;
  },
  SET_AUTOSAVE_DRAFT_ORDER(state, value) {
    state.autosaveDraftOrder = value;
  },
};

export const actions: ActionTree<PosState, RootState> = {
  setCustomerContacItems({ commit }, items) {
    commit("SET_CUSTOMER_CONTACT_ITEMS", items);
  },
  fetchCacheParts(
    { commit, dispatch, getters },
    { rangeStart, count } = { rangeStart: 1, count: 0 },
  ) {
    return new Promise((resolve, reject) => {
      partsService
        .getPartsRecords(
          getters["session/getClient"],
          rangeStart,
          rangeStart + 999,
          "",
          "",
        )
        .then((response: any) => {
          if (response.parts_items && response.parts_items.length === 1000) {
            dispatch("fetchCacheParts", {
              rangeStart: rangeStart + 1000,
              count: response.parts_items.length + count,
            }).then((dispatchResponse: any) => {
              resolve({ success: true });
            });
          } else {
            commit("SET_CACHE_STATS", {
              key: "Parts",
              value: {
                cacheDateTime: Date.now(),
                cacheRecords: response.parts_items.length + count,
              },
            });
            resolve({ success: true });
          }
        })
        .catch((error: any) => {
          dispatch(
            "notification/add",
            {
              message: `Error Displaying Parts. ${error.message}.`,
              type: "error",
            },
            { root: true },
          );
          reject({ success: false, error: error });
        });
    });
  },

  fetchParts({ commit, dispatch }, { selection }) {
    return new Promise((resolve, reject) => {
      partsService
        .getPartsBySelection(selection)
        .then((response: any) => {
          commit("SET_PARTS", response.parts_items);
          resolve({ success: true });
        })
        .catch((error: any) => {
          dispatch(
            "notification/add",
            {
              message: `Error Displaying Parts. ${error.message}.`,
              type: "error",
            },
            { root: true },
          );
          reject({ success: false, error: error });
        });
    });
  },
  fetchCachePrices(
    { commit, dispatch },
    { params, rangeStart, count } = { rangeStart: 1, count: 0 },
  ) {
    !rangeStart && (rangeStart = 1);
    !count && (count = 0);
    return new Promise((resolve, reject) => {
      partsService
        .getPrices({
          client: "",
          part_no: "",
          cat: "",
          code: [],
          web_cats: "",
          status: [],
          customerId: "",
          reps: params.reps,
          rangeStart: rangeStart,
          rangeEnd: rangeStart + 999,
          correls:
            "desc category taxable web_category avail_qty upc image um status",
        })
        .then((response: any) => {
          if (response.price_items && response.price_items.length === 1000) {
            dispatch("fetchCachePrices", {
              params,
              rangeStart: rangeStart + 1000,
              count: response.price_items.length + count,
            }).then((dispatchResponse: any) => {
              resolve({ success: true });
            });
          } else {
            commit("SET_CACHE_STATS", {
              key: "Prices",
              value: {
                cacheDateTime: Date.now(),
                cacheRecords: response.price_items.length + count,
              },
            });
            resolve({ success: true });
          }
        })
        .catch((error: any) => {
          dispatch(
            "notification/add",
            {
              message: `Error Displaying Prices. ${error.message}.`,
              type: "error",
            },
            { root: true },
          );
          reject({ success: false, error: error });
        });
    });
  },
  fetchPrices(
    { dispatch, state },
    {
      client,
      code,
      cat,
      part_no,
      web_cats,
      status,
      customerId,
      reps,
      rangeStart,
      rangeEnd,
      metaData,
      coCode,
    },
  ) {
    return new Promise((resolve, reject) => {
      let fieldnameFilters = "";
      if (code !== "") {
        const myFiltersObject = { filters: [] };
        Utils.filterObjectAdd(myFiltersObject, "CODE", "NOT", code);
        Utils.filterObjectAdd(myFiltersObject, "STD.QTY", "IS", "");
        fieldnameFilters = JSON.stringify(myFiltersObject);
      }

      // Some Rover implementations may not be using the PRICE file. Need a way to determine if we should use the PARTS file instead.
      const searchUsingParts = store.getters["mrkControl/searchUsingParts"];
      const values = JSON.stringify(state.salesOrder.order.custom_fields);

      if (searchUsingParts) {
        partsService
          .getPartsRequest({
            client,
            part_no,
            cat,
            code,
            web_cats,
            fieldnameFilters,
            status,
            customerId,
            shipSequence: state.shippingAddress?.ship_seq,
            customFields: JSON.stringify(values),
            rangeStart,
            rangeEnd,
            metaData,
          })
          .then((response: any) => {
            const parts = response.parts_items;
            parts.map((part: any) => {
              part["desc"] = part.wrap_desc || "";
              part["part_number"] = part.part_no;
              return part;
            });
            resolve({
              success: true,
              parts: parts,
              filters: response.control_items?.find(
                (x: any) => x.control_id === "WEB",
              )?.web_category_items,
            });
          })
          .catch((error: any) => {
            reject({ success: false, error: error });
          });
      } else {
        partsService
          .getPrices({
            client,
            part_no,
            cat,
            code,
            web_cats,
            fieldnameFilters,
            status,
            customerId,
            shipSequence: state.salesOrder.order.ship_seq,
            reps,
            customFields: values,
            rangeStart,
            rangeEnd,
            correls:
              "desc category taxable web_category avail_qty upc image um status average_cost fractions",
            metaData,
            coCode,
          })
          .then((response: any) => {
            resolve({
              success: true,
              parts: response.price_items,
              filters: response.control_items?.find(
                (x: any) => x.control_id === "WEB",
              )?.web_category_items,
              total_records_found: response.total_records_found,
            });
          })
          .catch((error: any) => {
            reject({ success: false, error: error });
          });
      }
    });
  },
  addPartToOrder({ commit }, part) {
    commit("ADD_TO_ORDER", part);
  },
  replaceOrder({ commit }, newOrder) {
    commit("REPLACE_ORDER", newOrder);
  },
  clearOrder({ commit }) {
    commit("CLEAR_ORDER");
  },
  clearTenders({ commit }) {
    commit("CLEAR_TENDERS");
  },
  addTender({ commit }, tender) {
    commit("ADD_TO_TENDERS", tender);
  },
  removeTender({ commit }, index) {
    commit("REMOVE_TENDER", index);
  },
  updateTenderAmount({ commit }, { tenderIndex, tender }) {
    commit("UPDATE_TENDER_AMT", { tenderIndex, tender });
  },
  addOaLine({ commit }, overage) {
    commit("ADD_UPDATE_OA_LINE", overage);
  },
  addChange({ commit }, overage) {
    commit("ADD_CHANGE", overage);
  },
  setTranId({ commit }, id) {
    commit("SET_TRAN_ID", id);
  },
  setChangeAmount({ commit }, amount) {
    commit("SET_CHANGE_AMOUNT", amount);
  },
  clearCustomer({ commit }) {
    commit("SET_CUSTOMER", null);
  },
  setCustomer({ commit, state }, cust) {
    commit("SET_CUSTOMER", cust.cust_items[0]);
    if (cust.ar_items && cust.ar_items.length > 0) {
      const invoice = cust.ar_items[0];
      invoice.balance = Number(invoice.balance);
      invoice.type = "IN";
      commit("SET_STORED_CART_ITEMS", {
        type: "orderInvoice",
        items: [invoice],
      });
    } else if (cust.so_items && cust.so_items.length > 0) {
      const loadInPartsView = state.loadOpenOrdersInParts;
      const order = cust.so_items[0];

      if (order.status != "C" && loadInPartsView) {
        commit("SET_SALES_ORDER", {
          order: order,
          oldOrder: cloneDeep(order),
          customer_name: cust.cust_items[0].cust_name,
          date_created: new Date(),
          id: (crypto as any).randomUUID(),
        });
      } else {
        commit("SET_STORED_CART_ITEMS", {
          type: "summaryOrder",
          items: [cust.so_items[0]],
        });
      }
    }

    commit("SET_SELECTED_CATEGORY", "");
    commit("RESET_SELECTED_FILTERS_OPTIONS", []);
  },
  setRegister({ commit, dispatch }, regNum) {
    return new Promise((resolve, reject) => {
      regService
        .fetchRegister(regNum)
        .then((response: any) => {
          commit("SET_REGISTER", response.reg_items[0]);
          resolve({ success: true });
        })
        .catch((error: any) => {
          reject({ success: false, error: error });
        });
    });
  },
  updateRegister({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      regService
        .updateReg(payload)
        .then((response: any) => {
          commit("SET_REGISTER", response.response.record);
          resolve({ success: true, response: response });
        })
        .catch((error: any) => {
          dispatch(
            "notification/add",
            {
              message: `Could Not open Register #${payload.reg_id}. ${error.message}.`,
              type: "error",
            },
            { root: true },
          );
          reject({ success: false, error: error });
        });
    });
  },
  fetchHardwareRequest({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      regService
        .fetchHardwareRequest()
        .then((response: any) => {
          commit("SET_SESSION_ID", response.sessionID);
          resolve({ success: true, response: response.roverPrintCommand });
        })
        .catch((error: any) => {
          dispatch(
            "notification/add",
            {
              message: `Could not open Register capture Session ID. ${error.message}.`,
              type: "error",
            },
            { root: true },
          );
          reject({ success: false, error: error });
        });
    });
  },
  fetchRegisterHardware({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      regService
        .fetchRegisterHardware("", payload)
        .then((response: any) => {
          if (response.errors) {
            reject({ success: false, error: response.errors[0].error });
          } else {
            commit("SET_SESSION_OBJECT", response);
            resolve({ success: true, response: response });
          }
        })
        .catch((error: any) => {
          dispatch(
            "notification/add",
            {
              message: `Could not find specified Session ID. ${error.message}.`,
              type: "error",
            },
            { root: true },
          );
          reject({ success: false, error: error });
        });
    });
  },
  deleteRegisterHardware({ commit, dispatch, state }) {
    const deleteRequest = JSON.parse(JSON.stringify(state.sessionObject));
    deleteRequest.RegisterID = "Delete";
    return new Promise((resolve, reject) => {
      regService
        .updateRegisterHardware(deleteRequest)
        .then((response: any) => {
          if (response.status === "success") {
            deleteRequest.RegisterID = "";
            commit("SET_SESSION_OBJECT", deleteRequest);
          }
          resolve({ success: true, response: response });
        })
        .catch((error: any) => {
          dispatch(
            "notification/add",
            {
              message: `Could not open remove hardware register association. ${error.message}.`,
              type: "error",
            },
            { root: true },
          );
          reject({ success: false, error: error });
        });
    });
  },
  updateRegisterHardware({ commit, dispatch, state }, updateRegObj) {
    //const updateRequest = JSON.parse(JSON.stringify(state.sessionObject));
    //updateRequest.RegisterID = regId
    return new Promise((resolve, reject) => {
      regService
        .updateRegisterHardware(updateRegObj)
        .then((response: any) => {
          if (response.status === "success") {
            //commit("SET_SESSION_OBJECT", updateRequest);
            //dispatch('setRegister', regId);
            dispatch("setRegister", updateRegObj.RegisterID);
            resolve({ success: true, response: response });
          } else if (response.errors) {
            dispatch(
              "notification/add",
              {
                message: `Could not update hardware register association. ${response.errors[0].error}.`,
                type: "error",
              },
              { root: true },
            );
            resolve({ success: false, error: response.errors[0].error });
          } else {
            resolve({ success: false, response: response });
          }
        })
        .catch((error: any) => {
          dispatch(
            "notification/add",
            {
              message: `Could not open update hardware register association. ${error.message}.`,
              type: "error",
            },
            { root: true },
          );
          reject({ success: false, error: error });
        });
    });
  },
  async closeClearRegister({ dispatch, getters, rootGetters }) {
    return new Promise((resolve, reject) => {
      const newReg = JSON.parse(JSON.stringify(getters.getRegister));
      newReg.status = "Closed";
      dispatch("updateRegister", {
        reg_id: getters.getRegister.reg_id,
        newReg: newReg,
        oldReg: getters.getRegister,
        user: (rootGetters["session/getUserId"] as string).toUpperCase(),
      }).then(async (response) => {
        const resp = response.response.response;
        if (resp) {
          if (resp.data.tran_id) {
            await paymentService
              .getReceipt({
                key: resp.data.tran_id,
                user: (
                  rootGetters["session/getUserId"] as string
                ).toUpperCase(),
                reg: getters.getRegister.reg_id,
                email: "",
                format: "escpos",
              })
              .then(async (response: any) => {
                await localDesktopService.printReceipt(
                  response,
                  getters.getRegisterPrinter,
                );
              });
          }
        }
        dispatch("clearCustomer");
        dispatch("clearTenders");
        dispatch("clearOrder");
        dispatch("session/logout", null, { root: true });
        resolve({ success: true });
      });
    });
  },
  clearRegister({ commit }) {
    commit("SET_REGISTER", null);
  },
  updateInvoiceAmount({ commit }, { arIndex, ar }) {
    commit("UPDATE_AR_AMT", { arIndex, ar });
  },
  updateShipLine({ commit }, { liIndex, li }) {
    commit("UPDATE_SHIP_LINE", { liIndex, li });
  },
  removeFromOrder({ commit }, index) {
    commit("REMOVE_FROM_ORDER", index);
  },
  setUser({ commit }, user) {
    commit("SET_USER", user);
  },
  setTaxAmount({ commit }, amount) {
    commit("SET_TAX_AMOUNT", amount);
  },
  clearTaxAmount({ commit }) {
    commit("CLEAR_TAX_AMOUNT");
  },
  clearPOS({ commit }) {
    commit("CLEAR_PARTS");
    commit("CLEAR_ORDER");
    commit("CLEAR_TENDERS");
    commit("CLEAR_TAX_AMOUNT");
    commit("SET_TRAN_ID", null);
    commit("SET_LAST_ORDER_ID", null);
  },
  setLastOrderId({ commit }, id) {
    commit("SET_LAST_ORDER_ID", id);
  },
  clearLastOrderId({ commit }) {
    commit("SET_LAST_ORDER_ID", null);
  },
  setLastOrderCustomerContacts({ commit }, contacts) {
    commit("SET_LAST_ORDER_CUSTOMER_CONTACTS", contacts);
  },
  clearLastOrderCustomerContacts({ commit }) {
    commit("SET_LAST_ORDER_CUSTOMER_CONTACTS", []);
  },
  addRack({ commit }, rack) {
    commit("ADD_RACK", rack);
  },
  removeRacksById({ commit }, part_id) {
    commit("REMOVE_RACK_BY_PART_ID", part_id);
  },
  clearRacks({ commit }) {
    commit("CLEAR_RACKS");
  },
  setPriceStrategy({ commit }, priceStrategy) {
    commit("SET_PRICE_STRATEGY", priceStrategy);
  },
  toggleAutoAddParts({ commit }) {
    commit("TOGGLE_AUTO_ADD_PARTS");
  },
  toggleAutoLoadDefaultCustomers({ commit }) {
    commit("TOGGLE_AUTO_LOAD_DEFAULT_CUSTOMERS");
  },
  toggleLoadOpenOrdersInParts({ commit }) {
    commit("TOGGLE_LOAD_OPEN_ORDERS_IN_PARTS");
  },
  setAutoAddParts({ commit }, autoAddParts) {
    commit("SET_AUTO_ADD_PARTS", autoAddParts);
  },
  setRegisterPrinter({ commit }, registerPrinter) {
    commit("SET_REGISTER_PRINTER", registerPrinter);
  },
  setDefaultPickTicketPrinter({ commit }, defaultPickTicketPrinter) {
    commit("SET_DEFAULT_PICKTICKET_PRINTER", defaultPickTicketPrinter);
  },
  setAutoLoadDefaultCustomers({ commit }, autoLoadDefaultCustomers) {
    commit("SET_AUTO_LOAD_DEFAULT_CUSTOMERS", autoLoadDefaultCustomers);
  },
  async setLastItemChanged({ commit }, lastItemChanged) {
    await commit("SET_LAST_ITEM_CHANGED", lastItemChanged);
  },
  setStoredCartItems({ commit }, { type, items }) {
    commit("SET_STORED_CART_ITEMS", { type, items });
  },
  clearStoredCartItems({ commit }) {
    commit("CLEAR_STORED_CART_ITEMS");
  },
  addUnsavedOrder({ commit }, order) {
    // could have a "then/catch/finally" here to handle promise returned
    salesService.saveDraftOrder(order);
  },
  getUnsavedOrders({ commit }) {
    // could have a "then/catch/finally" here to handle promise returned
    return salesService.getDraftOrders();
  },
  removeUnsavedOrder({ commit }, id) {
    // could have a "then/catch/finally" here to handle promise returned
    return salesService.deleteDraftOrder(id);
  },
  getTaxes({ dispatch, rootGetters, state }) {
    // Tax calculation can be calculated using the validation API.
    const posSkipCalcTax = store.getters["mrkControl/posSkipCalcTax"];

    if (posSkipCalcTax) {
      return;
    }

    // No need to calculate taxes if there are no items in the order.
    const lineItems = state.salesOrder.order?.lis_items || [];
    if (lineItems.length === 0) {
      return;
    }

    staxService
      .calcStax(state.salesOrder.order)
      .then((response: any) => {
        if (response.status === "success") {
          dispatch("setTaxAmount", response.tax_amount);
        } else {
          const notification = {
            message: `Unable to calculate taxes. ${response.error}.`,
            type: "error",
          };
          dispatch("notification/add", notification);
        }
      })
      .catch((error) => {
        const notification = {
          message: error.response.data.error || error.toString(),
          type: "error",
        };
        dispatch("notification/add", notification);
      });
  },
  setSalesOrder({ commit }, order) {
    Utils.salesOrderSetCustomFields(order.order);
    Utils.salesOrderLineItemsSetCustomFields(order.order);
    commit("SET_SALES_ORDER", order);
  },
  saveSalesOrder({ state, commit }) {
    // switching to object needs an action to save the order
    commit("SET_SALES_ORDER", state.salesOrder);
  },
  hydrateSalesOrder({ commit, state }) {
    // refreshing the order from the object needs an action to hydrate the order
    const order = new Order();
    order.initFromSalesOrder(state.salesOrder.order);
    const oldOrder = new Order();
    oldOrder.initFromSalesOrder(state.salesOrder.oldOrder);

    commit("SET_SALES_ORDER", {
      order: order,
      oldOrder: oldOrder,
      customer_name: state.salesOrder.customer_name,
      date_created: state.salesOrder.date_created,
      id: state.salesOrder.id,
      racks: state.salesOrder.racks,
    });
  },
  clearSalesOrder({ commit }) {
    commit("CLEAR_SALES_ORDER");
  },
  setCacheStats({ commit }, { key, value }) {
    commit("SET_CACHE_STATS", { key, value });
  },
  setCurrentActivity({ commit }, activity) {
    commit("SET_CURRENT_ACTIVITY", activity);
  },
  setHidePosCustomLineItems({ commit }, hidePosCustomLineItems) {
    commit("SET_HIDE_POS_CUSTOM_LINE_ITEMS", hidePosCustomLineItems);
  },
  async validateSalesOrder(
    { state, commit, dispatch },
    payload = { oldSalesOrder: {}, metaData: {} },
  ) {
    const posAutoValidate = store.getters["mrkControl/posAutoValidate"];
    let status = "success";
    if (posAutoValidate && !state.validationLoading) {
      commit("SET_VALIDATION_LOADING", true);
      try {
        const response = await salesService.validateSalesOrder({
          newSalesOrder: state.salesOrder.order,
          oldSalesOrder: payload.oldSalesOrder || state.salesOrder.oldOrder,
          user: state.user || "",
          metaData: JSON.stringify(payload.metaData),
          returnRecord: "Y",
        });
        if (
          !(
            response.error === null ||
            response.error === "" ||
            response.error === undefined
          )
        ) {
          status = "failed";
          const message = response.error;
          dispatch(
            "notification/add",
            {
              message: `${message}`,
              type: "error",
              dialog: true,
            },
            { root: true },
          );
        }

        if (response.fdict_items && response.fdict_items.length) {
          response.fdict_items.forEach((item: any) => {
            store.commit("fdict/ADD_FDICT", item);
          });
        }

        commit("SET_VALIDATE_SALES_ORDER", {
          record: response.record,
          oldRecord: response.oldRecord ?? {},
        });
      } catch {
        status = "failed";
      } finally {
        commit("SET_VALIDATION_LOADING", false);
      }
    }
    return { status: status };
  },
  setSelectedFiltersOptions({ commit }, options) {
    commit("SET_SELECTED_FILTERS_OPTIONS", options);
  },
  clearSelectedFiltersOptions({ commit }) {
    commit("SET_SELECTED_FILTERS_OPTIONS", []);
  },
  setSelectedCategory({ commit }, category) {
    commit("SET_SELECTED_CATEGORY", category);
  },
  setEntryUser({ commit }, user) {
    commit("SET_ENTRY_USER", user);
  },
  setRegisterOptions({ commit }, options) {
    commit("SET_REGISTER_OPTIONS", options);
  },
  setLastOrder({ commit }, order) {
    commit("SET_LAST_ORDER", order);
  },
  setKeyboardShortcutsMode({ commit }, value) {
    commit("SET_KEYBOARD_SHORTCUTS_MODE", value);
  },
  setAutosaveDraftOrder({ commit }, value) {
    commit("SET_AUTOSAVE_DRAFT_ORDER", value);
  },
};
export const pos: Module<PosState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
